import { useState } from 'react';
import Editable from './editable';
import plus from "../Routes/Languages/svgs/plus.svg";
import MainMenuItem from './MainMenuItem';

function MainVertical(props) {
    const [edit, setEdit] = useState(null)
    const { option, level } = props
    return (
        <div className='vertical-layout' style={{ marginLeft: level > 1 ? `0px` : `${250}px` }}>
            <>
                <div className='parent-info minfo minfo-cat' style={{ paddingLeft: "20px" }}>
                    {<div className='parent-icon minfo'>{<img alt='icon' src={option.icon} width={"20px"} height="20px" />}</div>}
                    <div className='parent-name'>{option.name}</div>
                    <div className='plus-con'>{<img alt='plus' width={"20px"} height="20px" src={plus} />}</div>
                </div>
                <div className='parent-childs child-parent'>
                    {option.childreen.map((m, index) => (
                        <>
                            {
                                edit && edit.name === m.name ?
                                    <Editable key={index} edit={edit} child={m} setEdit={(e) => setEdit(e)} /> :
                                    <MainMenuItem key={index} EnableEdit={edit} setEdit={(e) => setEdit(m)} level={level}
                                        child={m} {...props} />
                            }
                        </>))}
                </div>
            </>
        </div>
    )
}

export default MainVertical