import NavItem from './NavItem'
import plus from "../Routes/Languages/svgs/plus.svg"
import { trans } from '../../../Internationalization'

function VerticalLayout(props) {
    const { setActiveLink, ActiveLink, main, AddLayout, viewedList } = props

    return (
        <div className={'vertical-layout' + ` active-menu ${props.foc && "mt-20"}`}>
            {props.option.map((child, index) => (
                <div key={index}>
                    <div className='parent-info minfo'>
                        {<div className='parent-icon minfo'>{child.icon &&
                            <img alt='plus' width={"20px"} height="20px" src={child.icon} />}</div>}
                        <div className='parent-name'>{trans(child.key)}</div>
                        {!props.main && <div className='plus-con'>{<img alt='plus' width={"20px"} height="20px" src={plus} />}</div>}
                    </div>
                    <div className='parent-childs'>
                        {child.childreen.map((childs, ind) => (
                            <NavItem key={ind} AddLayout={(e, level, element) => AddLayout(e, 0, element)} viewedList={viewedList}
                                main={main} ActiveLink={ActiveLink} setActiveLink={(e) => setActiveLink(e)}
                                child={childs} />))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default VerticalLayout